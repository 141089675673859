.skills {
  margin-top: 6rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-size: 3rem;
    color: #495057;
    margin-bottom: 1.5rem;
  }

  .skills-icons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 70%;

    .icon-holder {
      border: 2px solid #007f5f;
      width: 100px;
      height: 100px;
      border-radius: 10%;
      padding: 1rem;
      font-size: 10rem;
      margin: 1.5rem;
      transition: 0.4s;
      color: #495057;
      box-shadow: 2px 2px 10px #70807c;
      &:hover {
        width: 110px;
        height: 110px;
        box-shadow: 2px 2px 10px #007f5f9f;
      }
    }
  }
}
