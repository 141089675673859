.portfolio__content {
  display: flex;
  align-items: center;
  flex-direction: column;
  h1 {
    margin-top: 4vh;
    font-size: 4rem;
  }
  .portfolio__cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 3vw;
    margin-top: 4rem;
    justify-content: center;
    .card {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: 40vw;
      max-width: 400px;
      min-width: 227px;
      border-radius: 2%;
      padding: 1rem;
      margin-right: 5rem;
      margin-bottom: 3rem;
      transition: 0.8s;
      box-shadow: 2px 2px 10px #70807c;
      &:hover {
        transform: translateY(-1%);
        box-shadow: 2px 2px 15px #70807c;
      }
      h3 {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
      }
      img {
        width: 100%;
        object-fit: contain;
      }
      .card__icons {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin-top: 1.3rem;
        border-top: 1px solid #495057;
        a {
          text-decoration: none;
          font-size: 2.5rem;
          transition: 0.4s;
          color: #495057;
          background-color: #a4acc4;
          margin-top: 1.3rem;
          margin-bottom: 0.5rem;
          border-radius: 10%;
          width: 60px;
          height: 55px;
          padding: 0rem;
          padding-left: 0.6rem;
          &:hover {
            text-decoration: none;
            opacity: 0.8;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1400px) {
    h1 {
      margin-right: 5rem;
      margin-top: 10rem;
    }
    .card__icons {
      a {
        font-size: 10rem;
      }
    }
  }
}
