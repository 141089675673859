.navbar {
  height: 100vh;
  color: black;
  background-color: #bec3d1;
  .nav {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }

  .profile {
    width: 100%;
    text-align: center;
    margin-top: 1rem;
    border-bottom: 4px solid #dee2e6;
    img {
      width: 60%;
      border-radius: 100%;
      border: none;
      margin-bottom: 1rem;
      transition: 0.4s;
      &:hover {
        width: 65%;
      }
    }
  }

  .nav-items {
    flex: 0.9;
    width: 100%;
    padding-top: 41vh;
    .nav-item {
      list-style: none;
      text-align: center;
      a {
        text-decoration: none;
        font-size: 1.4rem;
        color: inherit;
        display: block;
        padding: 0.5rem 0;
        transition: 0.4s;
        &:hover {
          font-size: 1.6rem;
          font-weight: 500;
        }
      }
    }
  }
  .active {
    background-color: #007f5f;
  }

  .footer {
    width: 100%;
    border-top: 4px solid #dee2e6;
    flex: 0.05;
    p {
      font-size: 1rem;
      text-align: center;
      padding: 1rem 0;
    }
  }
}
