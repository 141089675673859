.App {
  .sidebar {
    width: 20%;
    height: 100vh;
    background-color: #0000002a;
    position: fixed;
    z-index: 11;
    border-right: 1px solid #dee2e6;
    transition: all 0.4s ease-out;
    -webkit-transition: 0.4 ease-out;

    @media screen and (max-width: 1000px) {
      transform: translateX(-100%);
      width: 30%;
    }

    @media screen and (max-width: 411px) {
      width: 50%;
    }
  }

  .nav-toggle {
    @media screen and (max-width: 1000px) {
      transform: translateX(0);
    }
  }

  .main-content {
    width: 80%;
    margin-left: 20%;
    background-color: #dee2e6;
    min-height: 100vh;
    background-image: url("../img/dots.svg");
    display: grid;
    position: relative;
    @media screen and (max-width: 1000px) {
      margin-left: 0;
      width: 100%;
    }
  }
}

.nav-btn {
  position: fixed;
  z-index: 10;
  right: 8%;
  top: 6%;
  width: 4rem;
  height: 5rem;
  display: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  .line-1,
  .line-2,
  .line-3 {
    height: 0.4rem;
    width: 100%;
    background-color: #495057;
    pointer-events: none;
    display: none;
    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }
  @media screen and (max-width: 1000px) {
    display: block;
    .line-1,
    .line-2,
    .line-3 {
      display: block;
    }
  }
}
