@import "./styles/layout";
@import "./styles/navbar";
@import "./styles/typography";
@import "./styles/about";
@import "./styles/skills";
@import "./styles/page404";
@import "./styles/portfolio";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  color: #495057;
  background-color: #dee2e6;
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-size: 1.2rem;
}

@media screen and (max-width: 411px) {
  html {
    font-size: 70%;
  }
}
