.about {
  display: flex;
  flex-direction: column;
  .about-resume {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0;
    transform: translateY(-50%);
    p {
      font-size: 1rem;
      color: black;
    }
    a {
      font-size: 4rem;
      text-decoration: none;
      color: #007f5f;
    }
  }

  .about-page {
    display: flex;
    flex-direction: row;
    margin-left: 40vw;
    margin-top: 50vh;
    transform: translate(-50%, -50%);
    width: 80%;
    .about-image {
      margin-top: 0.5rem;
      margin-right: 1.5rem;
      padding: 0.5rem;
      width: 30%;
      transition: 0.4s;
      img {
        border-radius: 100%;
        border: 2px solid #007f5f;
        width: 100%;
        max-width: 15vw;
        transition: 0.4s;
      }
    }
    .about-text {
      width: 60%;
    }
    h1 {
      text-align: left;
      color: #007f5f;
    }

    @media screen and (max-width: 1400px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left: 50%;
      width: 80%;
      margin-bottom: 0;
    }
  }
}
